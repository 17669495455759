@import '@styles/theme/theme';

$transition: all 0.3s ease-in-out;

.root {
  $mono-font: var(--mono-font);

  display: flex;
  gap: 136px;
  align-items: baseline;
  max-width: 1312px;
  min-height: 100vh;
  padding: 40px 0;
  color: $ai-agent-text-color;
  background-color: $ai-agent-bg;

  @include page-container();
  @include container-padding();

  h3,
  h4,
  h5 {
    font-family: $title-font;
    font-weight: 300;
  }

  em {
    display: block;
    max-width: 85%;
    margin-inline: auto;
  }

  ul,
  ol {
    margin-bottom: 16px;

    @include media('sm') {
      margin-bottom: 24px;
    }

    > li {
      margin-bottom: 8px;
      margin-left: 20px;
    }
  }

  .list {
    list-style-type: disc;
  }

  .link {
    text-decoration: 2px underline $secondary;
    text-underline-offset: 4px;
    transition: $transition;
  }

  p {
    margin-bottom: 16px;

    @include media('sm') {
      margin-bottom: 24px;
    }
  }

  li,
  p {
    font-size: 16px;
  }

  .title,
  .subtitle {
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
    color: $ai-agent-text-color;

    @include media('sm') {
      margin-top: 64px;
      margin-bottom: 48px;
      font-size: 28px;
      line-height: 36px;
    }
  }

  .boxed-title {
    width: fit-content;
    padding: 8px 16px;
    margin-bottom: 24px;
    font-family: $mono-font;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $ai-agent-text-color;
    text-transform: uppercase;
    background: #fafafa1a;
    border: 1px solid $ai-agent-text-color;

    @include media('sm') {
      margin-bottom: 32px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .section {
    margin-bottom: 32px;

    @include media('sm') {
      margin-bottom: 48px;
    }
  }

  .sidebar {
    display: none;

    @include media('lg') {
      position: sticky;
      top: 140px;
      display: block;
      flex-basis: 412px;
      min-width: 412px;
    }

    .list {
      list-style-type: none;

      > li {
        padding-inline-start: 0;
        padding-left: 20px;
        margin-bottom: 0;
        margin-left: 0;
        border-left: 1px solid $ai-agent-neutral-500;

        &.active,
        &.has-active {
          border-left-color: $ai-agent-accent;
        }
      }
    }

    .sublist {
      gap: 15px;
      margin: 0;
      list-style-type: disc;

      > li {
        margin-bottom: 0;

        > a {
          font-size: 16px;
          line-height: 24px;

          &.active {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &::marker {
          color: currentcolor;
          transition: $transition;
        }
      }

      &.level-1 {
        margin-left: 0;
      }

      &.level-2 {
        margin-left: 20px;
      }
    }

    .li,
    .link {
      font-size: 18px;
      line-height: 24px;
      color: $ai-agent-neutral-300;
      text-decoration: none;
      transition: $transition;

      &:hover {
        color: $ai-agent-text-color;
      }

      &.active {
        color: $ai-agent-accent;

        > a {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .li {
      &.has-active,
      &.active {
        > a {
          font-size: 24px;
          line-height: 32px;
          color: $ai-agent-accent;
        }
      }
    }
  }
}
