@import '@styles/theme/theme';

$transition: all 0.3s ease-in-out;

.sidebar {
  display: none;

  @include media('lg') {
    position: sticky;
    top: 140px;
    display: block;
    flex-basis: 412px;
    min-width: 412px;
  }

  .list {
    list-style-type: none;

    > li {
      padding-inline-start: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 20px;
      margin-bottom: 0;
      margin-left: 0;
      border-left: 1px solid $ai-agent-neutral-500;

      &.active,
      &.has-active {
        border-left-color: $ai-agent-accent;
      }
    }
  }

  .sublist {
    gap: 15px;
    margin: 0;
    list-style-type: disc;

    > li {
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 0;

      > a {
        font-size: 16px;
        line-height: 24px;

        &.active {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &::marker {
        color: currentcolor;
        transition: $transition;
      }
    }

    &.level-1 {
      margin-left: 0;
    }

    &.level-2 {
      margin-left: 20px;
    }
  }

  .li,
  .link {
    font-size: 18px;
    line-height: 24px;
    color: $ai-agent-neutral-300;
    text-decoration: none;
    transition: $transition;

    &:hover {
      color: $ai-agent-text-color;
    }

    &.active {
      color: $ai-agent-accent;

      > a {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .li {
    &.has-active,
    &.active {
      > a {
        font-size: 24px;
        line-height: 32px;
        color: $ai-agent-accent;
      }
    }
  }
}
