@import '@styles/theme/theme';

.main {
  max-width: 600px;
  padding: 66px 0 16px;
  margin-inline: auto;
  font-family: $title-font;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;

  @include media('sm') {
    padding: 140px 0 24px;
    font-size: 36px;
    line-height: 44px;
  }
}

.sub {
  padding-top: 66px;
  padding-bottom: 40px;
  margin-inline: auto;
  font-family: $title-font;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;

  br {
    display: none;
  }

  @include media('sm') {
    padding-top: 140px;
    font-size: 48px;
    line-height: 56px;
  }

  @include media('ml') {
    br {
      display: block;
    }
  }
}

.main,
.sub {
  padding-inline: 20px;
}
