@import '@styles/theme/theme';

$transition: all 0.5s ease-in-out;

.trigger {
  .image {
    max-width: 100%;
    height: auto;
    filter: grayscale(100%);
    transition: $transition;
  }

  .cta {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin-inline: auto;
    color: $ai-agent-text-color;
    border: 1px solid transparent;
    transition: $transition;

    &__text {
      font-family: var(--mono-font);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000; // header has z-index 900...
  width: 90vw;
  max-width: 1280px;
  max-height: 95vh;
  padding: 25px;
  overflow-y: auto;
  text-align: center;
  background-color: $ai-agent-neutral-0;
  border-radius: 6px;
  box-shadow:
    hsl(206deg 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206deg 22% 7% / 20%) 0 10px 20px -15px;
  transform: translate(-50%, -50%);
  animation: content-show 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }

  .title {
    margin: 10px 0 20px;
    font-size: 18px;
    line-height: 1.5;
  }
}

@keyframes overlay-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes content-show {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.icon-button {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.video-wrapper {
  width: 100%;
  aspect-ratio: 16 / 9;

  .player {
    position: relative;
    padding-top: 56.25%;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.trigger-btn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: $transition;
  appearance: none;

  &:hover {
    .image {
      filter: grayscale(0%);
    }

    .cta {
      color: $ai-agent-accent;
    }
  }

  &:focus {
    outline: none;

    .image {
      filter: grayscale(0%);
    }

    .cta {
      color: $ai-agent-accent;
      border-color: $ai-agent-accent;
    }
  }

  &:active {
    .image {
      filter: grayscale(0%);
    }

    .cta {
      color: $ai-agent-dark-accent;
    }
  }
}

.close-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
