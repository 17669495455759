@import '@styles/theme/theme';

.root {
  font-family: $title-font;
  color: $ai-agent-text-color;
  text-align: center;
  background-color: $ai-agent-bg;
  background-image: url('./sphere.png');
  background-repeat: no-repeat;
  background-position: center left;

  .footer-top {
    padding: 80px 16px 188px;
    background: linear-gradient(180deg, #232426 0%, rgb(35 36 38 / 0%) 100%);

    @include media('sm') {
      padding-inline: auto;
      padding-top: 112px;
      padding-bottom: 112px;
    }

    .subtitle {
      margin-bottom: 24px;
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;

      .accent {
        color: $ai-agent-accent;
      }

      @include media('sm') {
        margin-bottom: 32px;
        font-size: 48px;
        line-height: 56px;
      }
    }

    .ai-agent-button {
      padding: 8px 24px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: #000;
      background-color: $ai-agent-accent;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgb(208 244 241 / 100%);
      }

      &:focus {
        background-color: rgb(69 213 197 / 100%);
        border: 1px solid rgb(115 223 212 / 100%);
        outline: none;
        box-shadow: 0 0 6px 0 rgb(115 223 212 / 100%);
      }

      &:active {
        background: rgb(18 162 146 / 100%);
        border: 1px solid rgb(115 223 212 / 100%);
      }
    }
  }

  p {
    margin-top: 30px;
    font-size: 18px;
    color: white;
    text-align: center;
  }

  .logo {
    width: 268px;
    height: 64px;
  }

  .outro {
    margin: 0 auto;
    font-size: 14px;
    color: white;
    text-align: center;
  }

  .terms {
    position: relative;
    display: flex;
    width: fit-content;
    padding-bottom: 40px;
    margin: 30px auto 0;
    font-size: 14px;
    color: white;
    text-align: center;

    a {
      color: white;
    }
  }
}
