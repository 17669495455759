@import '@styles/theme/theme';

.root {
  @include page-container();
  @include container-padding();

  --swiper-pagination-color: #73dfd4;
  // --swiper-pagination-left: auto
  // --swiper-pagination-right: 8px;
  // --swiper-pagination-bottom: 8px;
  // --swiper-pagination-top: auto;
  // --swiper-pagination-fraction-color: inherit;
  // --swiper-pagination-progressbar-bg-color: rgb(0 0 0 / 25%);
  // --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #b6b6b7;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  // --swiper-pagination-bullet-vertical-gap: 6px;

  // display: grid;
  // grid-template-columns: repeat(1, minmax(0, 1fr));
  // gap: 40px;
  padding: 40px 16px !important;

  // @include media('sm') {
  //   grid-template-columns: repeat(3, minmax(0, 1fr));
  // }

  .image-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .image {
      max-width: 100%;
      height: auto;
      transition: all 0.2s ease-in-out;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80px;
      height: 80px;
      font-size: 40px;
      color: $secondary;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      transform: translate(-50%, -50%);
    }

    &:hover {
      .image {
        opacity: 0.8;
        transform: scale(1.05);
      }

      .icon {
        opacity: 1;
      }
    }
  }
}
